.container-about{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    align-content: center;
    background-color: darkseagreen;
    text-align: center;
    padding: 20px;
}


.element-block {
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    margin: 0 auto;
}
